import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import StudyDesign from '../../../components/study-design'
import chart1 from '../../../images/Continuous_LH_Chart.png'
import chart2 from '../../../images/Sustained_LH_Chart_2022.png'

const metaTags = {
  description: 'See LH suppression clinical data and study design for SUPPRELIN® LA (histrelin acetate) subcutaneous implant. View Important Safety Information.',
  keywords: 'Homepage',
  title: 'LH Suppression | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h2 className="eye-brow">IN A PHASE 3 CLINICAL TRIAL:</h2>
        <h1>Continuous LH suppression through Month 12<sup>1</sup></h1>
        <ul className='brand-list'>
          <li>100% of patients (n=36) achieved LH suppression at one month</li>
          <li>100% of patients maintained LH suppression after Month 1 through Month 12</li>
        </ul>
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12}>
        <img src={chart1} alt="LH Data 12 months" title="LH Data 12 months" />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12}>
        <StudyDesign>
          <p className='study-copy'>
            <strong>STUDY DESIGN:</strong> A Phase 3, prospective, multicenter, open-label study was conducted in 36 children
            (33 females and 3 males), 4.5 to 11.6 years of age, to evaluate the efficacy and safety of a 50-mg histrelin
            subcutaneous implant for the treatment of CPP in treatment-naïve (n=20) and pretreated (n=16)
            children. Baseline patient characteristics were typical of patients with CPP. Efficacy assessments
            included endpoints that measured the suppression of gonadotropins (LH and FSH) and gonadal sex steroids (estrogen in
            girls and testosterone in boys) on treatment. Other assessments were clinical (evidence of stabilization or regression
            of signs of puberty) or gonadal steroid-dependent (bone age advancement, linear growth). The primary measure of
            efficacy was LH suppression. The initial duration of therapy was 12 months (plus a 1-month follow-up period). At the
            12-month visit, all patients had their initial implant from Day 1 removed and those patients who continued to meet all
            efficacy and safety parameters (as determined by the investigators) were eligible to receive a new 50-mg histrelin
            subcutaneous implant, with the possibility of rolling over into multiple 12-month extension phases.<sup>1,2</sup>
          </p>
          <p className='study-copy'>
            FSH=follicle-stimulating hormone.<br/>
            LH=luteinizing hormone.
          </p>
        </StudyDesign>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h2 className="eye-brow">In the Phase 3 Clinical Trial Extension:</h2>
        <h1>Sustained LH suppression through 6 years<sup>3</sup></h1>
        <ul className='brand-list'>
          <li>No patient had a mean peak stimulated LH >2.3 mIU/mL</li>
        </ul>
      </Col>
    </Row>
    <Row center="xs">
      <Col md={12} xs={12}>
        <img src={chart2}  alt="LH Data 72 months" title="LH Data 72 months" />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12}>
        <StudyDesign>
          <p className='study-copy'><strong>STUDY DESIGN:</strong> A long-term extension of a Phase 3, prospective, open-label study evaluating the efficacy and safety of a 50-mg histrelin subcutaneous implant in children with CPP.<sup>2</sup> Thirty-one patients deemed clinically appropriate for continued treatment were eligible to enter the study extension; the previous implant was removed after 12 months and a new implant inserted annually for up to 72 months, until the patient no longer required hormone suppression or the patient discontinued from the study. The primary endpoint was LH suppression as measured in response to gonadotropin releasing hormone analog (GnRHa) stimulation. Efficacy assessments also included secondary endpoints that measured the suppression of FSH and gonadal sex steroids (estrogen in girls and testosterone in boys) on treatment. Other assessments were clinical (evidence of stabilization or regression of signs of puberty) or gonadal steroid-dependent (bone age advancement, linear growth).<sup>3</sup></p>
          <p className='study-copy'>FSH=follicle-stimulating hormone.
            <br />GnRHa=gonadotropin-releasing hormone agonist.
              <br />LH=luteinizing hormone.
          </p>
        </StudyDesign>
      </Col>
    </Row>
    <Row>
      <Col md={12} xs={12}>
        <ul className='brand-list'>
          <li>31 out of 32 (97%) patients from the one-year study who were eligible continued in the long-term extension trial and received a new implant annually, as appropriate<sup>2</sup></li>
          <li>13 patients received a total of 4 implants (4th implant inserted from Months 37-48), 11 received a 5th implant (Months 49-60), and 2 received a 6th implant (Months 61-72); 8 patients received posttreatment, long-term follow-up<sup>3</sup></li>
          <li>Mean peak stimulated serum LH levels for all patients remained suppressed throughout the study and were below the expected peak pubertal response of 4 mIU/mL<sup>3</sup></li>
          <li>By 6 months of SUPPRELIN<sup>&reg;</sup> LA implant removal, mean peak stimulated LH levels increased to pubertal levels in both GnRHa treatment-experienced and GnRHa treatment-naïve patients<sup>3</sup></li>
        </ul>
        <p><strong>Discontinuation of SUPPRELIN<sup>&reg;</sup> LA should be considered at the discretion of the physician and at the appropriate time point for the onset of puberty (approximately 11 years for females and 12 years for males).<sup>1</sup></strong></p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/gonadal-hormone-clinical-data'}
          LinkCaption="View data on secondary efficacy hormone assessment – gonadal suppression - lh clinical data"
        >
          View data on secondary efficacy hormone assessment – gonadal suppression

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/downloads-office-support"
          LinkCaption="Discover important resources for your practice and caregivers - lh clinical data"
        >
          Discover important resources for your practice and caregivers
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
